import React from 'react';
import {Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button'
import {auth, isLogged} from "../services/firebase";
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';

function Login() {
    if (isLogged()) return (<Redirect to="/"/>)
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{height: 600, textAlign: 'center'}}
        >
            <div>
                <h1>Device Tracker</h1>
                <Button variant="outlined" onClick={auth}>Sign in with Google</Button>
            </div>
        </Grid>
    );
}

export default Login;
