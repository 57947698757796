import React, {Component} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';

export default function SelectUser({users, rowData, onChange}) {
    return (
        <FormControl>
            <Select
                labelId="mutiple-name-label"
                id="mutiple-name"
                multiple
                value={[rowData]}
                renderValue={value => {
                    if(!value[0]) return null;
                    return value[0].userName || 'None';
                }}
                onChange={event => {
                    onChange(event.target.value[1], rowData);
                }}
                input={<Input/>}
            >
                <MenuItem key={'NONE'} value={null}>
                    None
                </MenuItem>
                {users.map(user => (
                    <MenuItem key={user.id} value={user}>
                        {user.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
