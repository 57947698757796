import firebase from 'firebase';

firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASURMENT_ID
});

const db = firebase.firestore();

export function getDevices() {
    return new Promise((resolve, reject) => {
        db.collection("devices").get().then((querySnapshot) => {
            const devices = [];
            querySnapshot.forEach((doc) => {
                const device = doc.data();
                devices.push({
                    id: doc.id,
                    ...device
                });
            });
            resolve(devices);
        });
    })
}

export function getUsers() {
    return new Promise((resolve, reject) => {
        db.collection('users').get().then((querySnapshot) => {
            const users = [];
            querySnapshot.forEach((doc) => {
                const user = doc.data();
                users.push({
                    id: doc.id,
                    ...user
                });
            });
            resolve(users);
        });
    })
}

export function auth() {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider).then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        addNewUser(user);
    }).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        console.log('Error signing up:', error);
    });
}

export function logout() {
    firebase.auth().signOut().then(function () {
        console.log('Signed out...')
    }).catch(function (error) {
        console.log('Error on Signed out:', error);
    });
}

export function getDevicesWithUsers(devices) {
    return new Promise(async (resolve, reject) => {
        const devicesWithUsers = [];
        for (const device of devices) {
            if (device.userId) {
                const deviceWithUser = await getDeviceWithUser(device);
                devicesWithUsers.push(deviceWithUser);
            } else {
                devicesWithUsers.push(device);
            }
        }
        resolve(devicesWithUsers);
    })
}

function getDeviceWithUser(device) {
    return new Promise(async (resolve, reject) => {
        if (device.userId) {
            device.userId.get()
                .then(user => {
                    resolve({
                        ...device,
                        user: user.data(),
                    })
                })
        }
    })
}

function addNewUser(user) {
    const {uid, displayName, photoURL, email} = user
    db.collection('users').doc(uid).set({
        name: displayName,
        photoURL: photoURL,
        email: email,
    })
        .then(function () {
            console.log("Document successfully written!");
        })
        .catch(function (error) {
            console.error("Error writing document: ", error);
        });
}

export function onAuthStateChanged(callback) {
    firebase.auth().onAuthStateChanged(function (user) {
        callback(user ? true : false);
    });
}

export function isLogged() {
    const user = firebase.auth().currentUser;
    return user ? true : false;
}

export function onDevicesChanged(callback) {
    db.collection('devices')
        .onSnapshot(function (doc) {
            callback();
        });
}

export function addDevice(device) {
    const {name, os, osVersion} = device;
    db.collection('devices').doc().set({
        name: name,
        os: os,
        osVersion: osVersion,
    })
        .then(function () {
            console.log("Document successfully written!");
        })
        .catch(function (error) {
            console.error("Error writing document: ", error);
        });
}

export function updateDevice(device) {
    db.collection('devices').doc(device.id).set({
        name: device.name,
        os: device.os,
        osVersion: device.osVersion,
        ...device,
    })
        .then(function () {
            console.log("Document successfully written!");
        })
        .catch(function (error) {
            console.error("Error writing document: ", error);
        });
}

export function deleteDevice(device) {
    db.collection('devices').doc(device.id).delete().then(function () {
        console.log("Document successfully deleted!");
    }).catch(function (error) {
        console.error("Error removing document: ", error);
    });
}

export function updateUserOnDevice(user, device) {
    console.log('Event:', user, device);

    const updatedDevice = {
        name: device.name,
        os: device.os,
        osVersion: device.osVersion,
    }

    if (user) updatedDevice.user = user;

    db.collection('devices').doc(device.id).set(updatedDevice)
        .then(function () {
            console.log("Document successfully written!");
        })
        .catch(function (error) {
            console.error("Error writing document: ", error);
        });
}
