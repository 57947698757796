import React, {Component} from 'react';
import DevicesUi from './DevicesUi';
import {
    getDevices,
    getUsers,
    onDevicesChanged,
    addDevice,
    updateDevice,
    deleteDevice,
    updateUserOnDevice,
} from '../../services/firebase';

const UPDATE_DEVICES_TABLE_ENDPOINT = process.env.REACT_APP_UPDATE_DEVICES_TABLE_ENDPOINT;

class Devices extends Component {
    state = {
        devices: [],
        users: [],
        updatingDevicesTable: false,
    }

    async componentDidMount() {
        this.fetchDevices();
        this.fetchUsers();
        onDevicesChanged(this.fetchDevices);
    }

    updateDevicesTable = async () => {
        this.setState({updatingDevicesTable: true});
        try {
            const res = await fetch(UPDATE_DEVICES_TABLE_ENDPOINT);
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({updatingDevicesTable: false});
        }
    }

    fetchDevices = async () => {
        const devices = await getDevices();
        // Now adding user object directly to device item
        // const devicesWithUsers = await getDevicesWithUsers(devices);
        this.setState({devices: devices});
    }

    fetchUsers = async () => {
        const users = await getUsers();
        this.setState({users: users});
    }

    render() {
        return (<DevicesUi
            updateUserOnDevice={updateUserOnDevice}
            devices={this.state.devices}
            users={this.state.users}
            onAdd={addDevice}
            onUpdate={updateDevice}
            onDelete={deleteDevice}
            updateDevicesTable={this.updateDevicesTable}
            updatingDevicesTable={this.state.updatingDevicesTable}
        />);
    }
}

export default Devices;
