import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Devices from './views/Devices/index';
import Login from './views/Login';
import {onAuthStateChanged, logout} from './services/firebase';

import * as firebase from './services/firebase';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

function App() {
    const [isLogged, changeIsLogged] = useState(false);
    const classes = useStyles();

    useEffect((state) => {
        onAuthStateChanged(changeState);
    }, []);

    function changeState(state) {
        console.log('Called!');
        changeIsLogged(state);
    };

    return (
        <Router>
            <AppBar position="static" style={{marginBottom: 25, backgroundColor:'#262626'}}>
                <Toolbar>
                    {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">*/}
                    {/*    <MenuIcon />*/}
                    {/*</IconButton>*/}
                    <Typography variant="h6" className={classes.title}>
                        Device Tracker
                    </Typography>
                    {
                        isLogged && <Button color="inherit" onClick={logout}>Logout</Button>
                    }
                </Toolbar>
            </AppBar>
            <Switch>
                <Route path="/login">
                    <Login/>
                </Route>
                <PrivateRoute path="/">
                    <Devices/>
                </PrivateRoute>
            </Switch>
        </Router>
    );
}


function PrivateRoute({children, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location}) =>
                firebase.isLogged() ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}


export default App;
