import React, {useState} from 'react';
import MaterialTable from 'material-table';
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import QRCode from 'qrcode.react';
import SelectUser from './SelectUser';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 300,
        flexGrow: 1,
        minWidth: 300,
        transform: 'translateZ(0)',

        '@media all and (-ms-high-contrast: none)': {
            display: 'none',
        },
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        color: 'white',
        width: 400,
        backgroundColor: '#262626',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center',
    },
    downloadButton: {
        paddingTop: theme.spacing(2),
    }
}));

export default function Devices({devices, onAdd, onUpdate, onDelete, users, updateUserOnDevice, updateDevicesTable, updatingDevicesTable}) {
    const [currentDevice, setCurrentDevice] = useState(null);

    const handleDownloadQRClick = rowData => {
        setCurrentDevice(rowData);
    }

    const handleCloseModal = () => {
        setCurrentDevice(null);
    }

    const downloadQRCode = () => {
        const canvas = document.querySelector('.HpQrcode > canvas');
        const dataUrl = canvas.toDataURL();
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = currentDevice.id + '_QRCode.png';
        link.click();
    }

    const data = devices.map(value => ({
        ...value,
        userName: value.user ? value.user.name : '',
    }));
    const columns = [
        {
            title: 'QR code', field: 'qrcode', editable: 'never',
            render: rowData => (
                <Button color="primary" users={users} onClick={() => handleDownloadQRClick(rowData)}>Open</Button>)
        },
        {title: 'ID', field: 'id', editable: 'never'},
        {title: 'Name', field: 'name'},
        {title: 'OS', field: 'os'},
        {title: 'OS version', field: 'osVersion'},
        {
            title: 'User',
            field: 'userName',
            editable: 'never',
            render: rowData => <SelectUser users={users} rowData={rowData} onChange={updateUserOnDevice}/>
        },
    ];
    const classes = useStyles();
    const rootRef = React.useRef(null);
    return (
        <Container>
            <Button disabled={updatingDevicesTable} style={{marginBottom: 20}} variant="contained" color="primary"
                    onClick={updateDevicesTable}>Update Devices Table</Button>
            <MaterialTable
                title="Devices"
                columns={columns}
                data={data}
                editable={{
                    onRowAdd: newData => new Promise((resolve) => {
                        onAdd(newData);
                        resolve();
                    }),
                    onRowUpdate: (newData, oldData) => new Promise((resolve) => {
                        onUpdate(newData);
                        resolve();
                    }),
                    onRowDelete: oldData => new Promise((resolve) => {
                        onDelete(oldData);
                        resolve();
                    }),
                }}
                options={{
                    paging: false,
                    addRowPosition: 'first'
                }}
            />
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={!!currentDevice}
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className={classes.modal}
                container={() => rootRef.current}
                onClose={handleCloseModal}
            >
                <div className={classes.paper}>
                    <h2 id="server-modal-title">QR CODE for device {currentDevice && currentDevice.name}</h2>
                    <div className='HpQrcode'>
                        <QRCode value={currentDevice && currentDevice.id} size={300}/>
                    </div>
                    <div className={classes.downloadButton}>
                        <Button color="primary" variant="outlined" users={users}
                                onClick={downloadQRCode}>Download</Button>
                    </div>
                </div>
            </Modal>
        </Container>
    );
}

